import '../styles/pappascatering.scss';

import './app/accordion';
import './app/faq-nav';
import './app/gallery';
import './app/sticky-header';
import './app/mobile-nav';
import { createApp } from 'vue';
import PappasLeadsForm from '@pappas/leads-form';
// import PappasLeadsForm from '../../../pappas-leads-form/src/index.js';
import PappasEclub from '@pappas/eclub';
// import PappasEclub from '../../../pappas-eclub/src/plugin';

const app = createApp({});

app.use(PappasLeadsForm);
app.use(PappasEclub);

app.mount('.vue-wrapper');
