class StickyHeader {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => this.boot());
  }

  boot() {
    this.$el = document.querySelector('.siteHeader');
    this.toggleClass = 'siteHeader--compact';
    this.compact = false;

    this.setThreshold();

    document.addEventListener('scroll', () => this.scrolled());
    document.addEventListener('resize', () => this.setThreshold());
  }

  scrolled() {
    if ((this.belowThreshold() && !this.compact) || (this.aboveThreshold() && this.compact)) {
      this.toggle();
    }
  }

  toggle() {
    this.compact = !this.compact;
    this.$el.classList.toggle(this.toggleClass);
  }

  setThreshold() {
    this.threshold = parseInt(getComputedStyle(document.body).paddingTop);
  }

  aboveThreshold() {
    const pos = document.body.scrollTop
      ? document.body.scrollTop
      : document.documentElement.scrollTop;
    return pos < this.threshold;
  }

  belowThreshold() {
    return !this.aboveThreshold();
  }
}

export default new StickyHeader();
