class MobileNav {
    constructor() {
        document.addEventListener('DOMContentLoaded', () => this.boot());
    }

    boot() {
        this.$hamburger = document.querySelector('.navbar__toggle');
        this.$nav = document.querySelector('.navbar__nav');
        this.$hamburger.addEventListener('click', () => this.toggle());
    }

    toggle() {
        this.$hamburger.classList.toggle('is-active');
        this.$nav.classList.toggle('navbar__nav--active');
        document.body.classList.toggle('no-scroll');
    }
}

export default new MobileNav();
