import stickybits from 'stickybits';
import Gumshoe from 'gumshoejs';
import SmoothScroll from 'smooth-scroll';

class FaqNav {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => this.boot());
  }

  boot() {
    this.$el = document.querySelector('.faqs__nav');

    if (!this.$el) {
      return;
    }

    new Gumshoe('.faqs__nav a', {
      offset: () => document.querySelector('.siteHeader').getBoundingClientRect().height * 1.3,
    });

    new SmoothScroll('.faqs__nav a', {
      offset: () => document.querySelector('.siteHeader').getBoundingClientRect().height * 1.3,
    });

    stickybits(this.$el, {
      get stickyBitStickyOffset() {
        return document.querySelector('.siteHeader').offsetHeight / 1.666;
      },
    });
  }
}

export default new FaqNav();
