class Accordion {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => this.boot());
  }

  boot() {
    this.items = document.querySelectorAll('.accordionItem');

    if (!this.items) {
      return;
    }

    // ie fix
    this.items = Array.prototype.slice.call(this.items);

    this.active = null;

    this.items.forEach(item => {
      item.querySelector('.accordionItem__header').addEventListener('click', evt => {
        this.toggle(item);
      });
    });
  }

  toggle(item) {
    item.classList.contains('accordionItem--active') ? this.deactivate(item) : this.activate(item);
  }

  activate(item) {
    const current = document.querySelector('.accordionItem--active');

    if (current) {
      this.deactivate(current);
    }

    const targetHeight = getComputedStyle(item.querySelector('.accordionItem__body__inner')).height;

    item.querySelector('.accordionItem__body').style.maxHeight = targetHeight;
    item.classList.add('accordionItem--active');
  }

  deactivate(item) {
    item.querySelector('.accordionItem__body').style.maxHeight = 0;
    item.classList.remove('accordionItem--active');
  }
}

export default new Accordion();
