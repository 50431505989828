import { tns } from 'tiny-slider/src/tiny-slider';

class Gallery {
  constructor() {
    this.gridSelector = '.galleryGrid';
    this.lightboxSelector = '.galleryLightbox';
    this.carouselSelector = '.galleryLightbox__carousel';
    this.carouselIds = [];

    document.addEventListener('DOMContentLoaded', () => this.boot());
  }

  boot() {
    if (!document.querySelector(this.gridSelector)) {
      return;
    }

    this.bootLightbox();
    this.bootCarousel();
  }

  bootLightbox() {
    this.setCarouselIds();

    document
      .querySelector('.galleryLightbox__close')
      .addEventListener('click', e => this.closeLightbox());
    document
      .querySelector('.galleryLightbox__hitArea')
      .addEventListener('click', e => this.closeLightbox());

    let $images = document.querySelectorAll('.galleryGrid__item--image');

    // ie fix
    $images = Array.prototype.slice.call($images);

    $images.forEach(el => {
      el.addEventListener('click', e => this.openLightbox(e));
    });
  }

  bootCarousel() {
    this.carousel = tns({
      container: this.carouselSelector,
      items: 1,
      nav: false,
      mode: 'gallery',
      // center: true,
      // autoWidth: true,
      autoHeight: true,
    });
  }

  openLightbox(e) {
    e.preventDefault();

    this.carousel.goTo(this.carouselIds.indexOf(e.currentTarget.dataset.itemId));

    document.querySelector(this.lightboxSelector).classList.add('galleryLightbox--isActive');
    document.querySelector('body').classList.add('noscroll');
  }

  closeLightbox() {
    document.querySelector(this.lightboxSelector).classList.remove('galleryLightbox--isActive');
    document.querySelector('body').classList.remove('noscroll');
  }

  getCarouselIndex(id) {
    return this.carouselIds.indexOf(id);
  }

  setCarouselIds() {

    let $items = document.querySelectorAll(this.carouselSelector + ' figure');

    // ie fix
    $items = Array.prototype.slice.call($items);

    $items.forEach((el, index) => {
      this.carouselIds[index] = el.dataset.itemId;
    });
  }
}

export default new Gallery();
